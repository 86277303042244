<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo main__logo__title">
        <img src="../../assets/images/logo/logo.svg" alt="" />
        <h2 class="brand-text ml-1 title">
          Enterprise<br />
          <span class="title_go">GoDigital</span>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold main__logo__title" title-tag="h2">
            Welcome to <span class="title">Enterprise </span
            ><span class="title_go">Go Digital</span>! 👋
          </b-card-title>
          <b-card-text class="mb-2"> Please sign-in to your account </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- Tenant Name -->
              <b-form-group label="Company Id" label-for="login-tenant">
                <validation-provider
                  #default="{ errors }"
                  name="company id"
                  vid="company id"
                  rules="required"
                >
                  <b-form-input
                    id="login-tenant"
                    v-model="userTenant"
                    :state="errors.length > 0 ? false : null"
                    name="login-tenant"
                    placeholder="Company Id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mb-2 d-flex justify-content-end">
                <b-form-checkbox
                  v-model="rememberMe"
                  name="remember-me"
                  value="true"
                  unchecked-value="false"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- checkbox -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                @click="Loading = true"
              >
                <b-spinner small v-if="Loading" />
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
//import axios from 'axios'
import api from "@/api/api-basic.js";
import jwtDecode from "vue-jwt-decode";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      permissions: [],
      password: "",
      userEmail: "",
      userTenant: "",
      sideImg: require("@/assets/images/pages/login-side.svg"),

      // validation rules
      required,
      email,
      rememberMe: false,
      Loading: false,
    };
  },
  computed: {
    ...mapGetters("tenantModule", {
      tenantId: "tenantsId",
      loading: "loading",
    }),

    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-side.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions("tenantModule", ["getTenantIdAction"]),
    error() {
      this.$swal({
        title: "Error!",
        text: " Login Failed",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    },
    base64UrlDecode(str) {
      let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
      while (base64.length % 4) {
        base64 += '=';
      }
      let decodedData = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(decodedData);
    },
    login() {
      if (this.rememberMe) {
        localStorage.setItem("userEmail", this.userEmail);
        localStorage.setItem("userTenant", this.userTenant);
      }
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          api.defaults.headers.tenant = this.userTenant;
          api
            .post("tokens", {
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              this.Loading = false;
              this.getTenantIdAction(this.userTenant).then(() => {
                localStorage.setItem("tenantId", this.tenantId);
              });

              useJwt.setToken(response.data.result.token);
              this.$store.commit("setToken", response.data.result.token);
              useJwt.setRefreshToken(response.data.result.refreshToken);
              sessionStorage.setItem(
                "sessionToken",
                JSON.stringify(response.data.result.token)
              );
            
              let tokenDec = this.base64UrlDecode(response.data.result.token.split('.')[1]);
              const userData = {
                id:
                  tokenDec[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
                  ],
                fullName: tokenDec["fullName"],
                has_default_password: tokenDec["has_default_password"],
                username:
                  tokenDec["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
                avatar: "/img/13-small.d796bffd.png",
                tenantLogo: response.data.result.tenantLogo,
                permissions: tokenDec["permission"],
                address: tokenDec["tenantAddress"],
                city: tokenDec["tenantCity"],
                country: tokenDec["tenantCountry"],
                expiryToken: response.data.result.refreshTokenExpiryTime,
                TwoFactorEnabled: tokenDec["TwoFactorEnabled"],
                email:
                  tokenDec[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
                  ], // Need to simplify name from API
                ability: [{ action: "manage", subject: "all" }],
                role: "admin",
              };

              localStorage.setItem("userData", JSON.stringify(userData));
              localStorage.setItem("userTenant", this.userTenant);

              this.$ability.update(userData.ability);

              console.log(tokenDec["TwoFactorEnabled"]);

              if (tokenDec["TwoFactorEnabled"] === "True") {
                this.$router.push({
                  name: "otp-verification",
                });
              } else {
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Welcome ${userData.fullName || userData.username}`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                      },
                    });
                  });
              }
            })
            .catch((error) => {
              this.Loading = false;
              let msg = error.response.data.message;
              this.$refs.loginForm.setErrors({
                tenant: [msg],
                email: [msg],
                password: [msg],
              }),
                this.error();
            });
        }
      });
    },
  },
  created() {
    this.userEmail = localStorage.getItem("userEmail") || "";
    this.userTenant = localStorage.getItem("userTenant") || "";
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.main__logo__title .title {
  color: #4065ab;
  font-weight: 700;
}

.main__logo__title .title_go {
  color: #b0cc48;
  font-weight: 700;
}

.main__logo__title img {
  width: 60px;
  height: auto;
}
</style>
