var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo main__logo__title"},[_c('img',{attrs:{"src":require("../../assets/images/logo/logo.svg"),"alt":""}}),_c('h2',{staticClass:"brand-text ml-1 title"},[_vm._v(" Enterprise"),_c('br'),_c('span',{staticClass:"title_go"},[_vm._v("GoDigital")])])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold main__logo__title",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to "),_c('span',{staticClass:"title"},[_vm._v("Enterprise ")]),_c('span',{staticClass:"title_go"},[_vm._v("Go Digital")]),_vm._v("! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please sign-in to your account ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":"Company Id","label-for":"login-tenant"}},[_c('validation-provider',{attrs:{"name":"company id","vid":"company id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-tenant","state":errors.length > 0 ? false : null,"name":"login-tenant","placeholder":"Company Id"},model:{value:(_vm.userTenant),callback:function ($$v) {_vm.userTenant=$$v},expression:"userTenant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"Email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-2 d-flex justify-content-end"},[_c('b-form-checkbox',{attrs:{"name":"remember-me","value":"true","unchecked-value":"false"},model:{value:(_vm.rememberMe),callback:function ($$v) {_vm.rememberMe=$$v},expression:"rememberMe"}},[_vm._v(" Remember Me ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid},on:{"click":function($event){_vm.Loading = true}}},[(_vm.Loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Sign in ")],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }